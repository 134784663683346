<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onDeliverableSave="onCreate"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
  >
    <template v-slot:content="{}">
      <NewDeliverableForm
        ref="form"
        class="pa-4"
        v-model="form"
      ></NewDeliverableForm>
    </template>
  </AView>
</template>
  
  <script>
import NewDeliverableForm from "../../../../../../components/wad/organisms/deliverables/forms/NewDeliverableForm.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { DELIVERABLE_BUTTONS } from '../../constants/actions.gh';
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    NewDeliverableForm,
    AView,
  },
  data: () => ({
    form: {},

  }),
  created() {},

  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        DELIVERABLE_BUTTONS.SAVE,
        this.value.permissions
      );
    },
  },

  methods: {
    onFocus(data) {
      console.log("=============Data: ", data);
    },
    onCreate() {
      const valid = this.$refs.form.validate();
      if (valid)
        this.$store.dispatch("DeliverableStore/CreateNewDeliverable", {
          from: this.value,
          newDeliverable: this.form,
        });
    },
  },
};
</script>
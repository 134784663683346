<template>
  <v-form v-bind="$attrs" ref="form" v-model="valid" lazy-validation>
    <HelpFormInputVue
      dark
      v-model="form.name"
      label="Deliverable Name"
      :rules="nameRules"
      :helpText="'This is a main name of the new deliverable'"
      required
    ></HelpFormInputVue>

    <SelectorDefaultVue
      class="mt-4"
      dark
      label="Deliverable Type"
      v-model="form.type"
      :items="deliverableTypes"
      :rules="deliverableTypeRules"
      :helpText="'You can select deliverable type to be able operate with current deliverable across the system. Please note that this change can not be undone.'"
      required
    ></SelectorDefaultVue>
  </v-form>
</template>


<script>
import SelectorDefaultVue from "../../../atoms/common/autocompletes/SelectorDefault.vue";
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    console.log("This value: ", this.value);

    return {
      valid: true,
      form: this.value,

      deliverableTypes: [
        {
          name: "Activity",
          value: "activity",
          description:
            "This is some activity that will be done by ADAAS Team as a part of service processing.<br> This is might be some kind of meetings, review, audit or anything else.",
        },
        {
          name: "Document",
          value: "document",
          description:
            "This is something that ADAAS Team will deliver as a document.<br> This is might be diagram, mind map, actual Document, or even spreadsheet.",
        },
        {
          name: "SLA",
          value: "sla",
          description: `SLA types is something that ADAAS Team will guarantee during service processing. <br>
            This is might be response time, documents structure, or anything else that impacts delivery process.`,
        },
        {
          name: "Artifact",
          value: "artifact",
          description: `Artifact type is some sort of deliverable that should be created and provided to the customer.  <br>
          The main difference between artifact and document type that artifact is something more like code, or vision, or costs assessment, etc`,
        },
      ],

      // ======rules
      nameRules: [(v) => !!v || "Deliverable Name is required"],
      deliverableTypeRules: [(v) => !!v || "Deliverable Type is required"],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorDefaultVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
};
</script>